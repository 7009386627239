import Phaser from "phaser";
import Scaling from "../configs/scaling";
import Constants from "../configs/constants"
import * as Helpers from "../configs/helpers";

import InputManager, { SwipeDirection } from "../components/controllers/inputManager";
import DayCycle from "../components/controllers/dayCycle";
import LevelBuilder from "../components/controllers/levelBuilder";

import Walls from "../components/containers/walls";
import HighscoreTracker from "../components/containers/highscoreTracker";
import Player from "../components/containers/player";

import Enemies from "../components/groups/enemies";
import Skylines from "../components/groups/skylines";
import Signs from "../components/groups/signs";
import Clouds from "../components/groups/clouds";
import Archs from "../components/groups/archs";

import Sky from "../components/sprites/sky";
import Ground from "../components/sprites/ground";
import Light from "../components/sprites/light";
import ElectricFence from "../components/sprites/electricFence";
import Pole from "../components/sprites/pole";
import Powerups from "../components/groups/powerups";
import UserInterface from "./userinterface";

import EnemyBase from "../components/sprites/enemies/enemyBase";
import Powerup from "../components/sprites/powerup";
import scoreController from "@/utils/game/ScoreController";
import sessionSaveFile from "@/utils/game/SessionSaveFile";
import globalSaveFile from "@/utils/game/GlobalSaveFile";
import { ScoreGameTypes } from "@/interfaces/ISessionFile";

export default class Main extends Phaser.Scene {
  sceneUserInterface!: UserInterface;
  isGameOver!: boolean;
  worldBoundLeft!: number;
  worldBoundRight!: number;
  inputManager!: InputManager;
  player!: Player;
  sun!: Phaser.GameObjects.Sprite;
  moon!: Phaser.GameObjects.Sprite;
  dayCycle!: DayCycle;
  clouds!: Clouds;
  skylines!: Skylines;
  highscoreTracker!: HighscoreTracker;
  levelBuilder!: LevelBuilder;

  audio_player_hit!: Phaser.Sound.BaseSound;
  audio_jump!: Phaser.Sound.BaseSound;
  audio_dash!: Phaser.Sound.BaseSound;
  audio_shield!: Phaser.Sound.BaseSound;
  audio_drone_hit!: Phaser.Sound.BaseSound;
  audio_powerup_default!: Phaser.Sound.BaseSound;
  audio_powerup_heal!: Phaser.Sound.BaseSound;
  audio_gameover!: Phaser.Sound.BaseSound;
  audio_highscore!: Phaser.Sound.BaseSound;
  walls!: Walls;
  ground!: Ground;
  electricFence!: ElectricFence;
  enemies!: Enemies;
  powerups!: Powerups;
  groundLight!: Light;
  pole!: Pole;


  constructor() {
    super({ key: "game" });
  }

  preload() {
    this.game.events.emit('start');

    // User Interface 
    this.sceneUserInterface = this.scene.get('userinterface') as UserInterface;
    this.scene.launch('userinterface');
  }

  create() {
    sessionSaveFile.create({
      type: ScoreGameTypes.INFINITE,
      stats: ['powerups', 'jumps', 'enemiesSpawned', 'dayCycleProgress']
    });

    // Global Settings 
    this.isGameOver = false;
    this.worldBoundLeft = Constants.WALL_WIDTH;
    this.worldBoundRight = this.cameras.main.width - Constants.WALL_WIDTH;

    // Day Cycle 
    this.sun = this.add.sprite(this.worldBoundRight, 0, 'sun').setOrigin(1, 0).setScrollFactor(0);
    this.moon = this.add.sprite(this.worldBoundLeft, 0, 'moon').setOrigin(0, 0).setScrollFactor(0);
    this.dayCycle = new DayCycle(this, {
      cycle: {
        start: 'night',
        steps: 100,

      },
      sun: {
        sprite: this.sun,
        position: {
          high: 0,
          low: this.cameras.main.height + this.sun.height
        }
      },
      moon: {
        sprite: this.moon,
        position: {
          high: 0,
          low: this.cameras.main.height + this.moon.height
        }
      }
    });

    // Background 
    const sky = new Sky(this, this.cameras.main.centerX, this.cameras.main.centerY);
    this.clouds = new Clouds(this, 30);
    this.skylines = new Skylines(this, 10);
    this.children.bringToTop(this.sun);
    this.children.bringToTop(this.moon);

    // Foreground 
    const archs = new Archs(this, 10);
    this.walls = new Walls(this, 0, 0, Constants.WALL_WIDTH);
    this.groundLight = new Light(this, this.cameras.main.centerX, this.cameras.main.height);
    this.pole = new Pole(this, 0, 0);
    this.ground = new Ground(this, this.cameras.main.centerX, this.cameras.main.height + Scaling.getPixelbyDPR(56), this.cameras.main.width, Scaling.getPixelbyDPR(56 * 2)).setOrigin(0.5, 1);
    const signs = new Signs(this, 10);
    this.enemies = new Enemies(this, 30);
    this.powerups = new Powerups(this, 30);
    this.player = new Player(this, this.cameras.main.centerX, this.cameras.main.height - Scaling.getPixelbyDPR(Constants.PLAYER_Y_OFFSET), Constants.PLAYER_LIVES);
    const electricFenceOffset = this.cameras.main.height - this.player.y + Scaling.getPixelbyDPR(20);
    this.electricFence = new ElectricFence(this, this.cameras.main.centerX, this.cameras.main.height + Scaling.getPixelbyDPR(20), this.cameras.main.width, Scaling.getPixelbyDPR(10), electricFenceOffset).setOrigin(0.5, 1);

    // Highscore //
    const highscore = globalSaveFile.getHighscore();
    if (highscore > 0) {
      this.highscoreTracker = new HighscoreTracker(this, 0, -(Helpers.getPixelsFromScore(highscore, this.cameras.main.height)), highscore);
    }

    // Level Builder //
    this.levelBuilder = new LevelBuilder(this, {
      enemies: this.enemies,
      ground: this.ground,
      archs: archs,
      skylines: this.skylines,
      clouds: this.clouds,
      signs: signs,
      powerups: this.powerups
    }, false);

    // Camera 
    this.cameras.main.startFollow(this.player, false, 0, 1, 0, (this.cameras.main.height / 2) - Scaling.getPixelbyDPR(Constants.PLAYER_Y_OFFSET))

    // Inputs 
    this.inputManager = new InputManager(this, {
      callbackSwipe: (pointer: Phaser.Input.Pointer, direction: SwipeDirection, result: Phaser.Math.Vector2) => {
        if(direction === SwipeDirection.NORTH || direction === SwipeDirection.NORTHEAST || direction === SwipeDirection.NORTHWEST) {
          this.player.liftoff();
          this.player.dash();
        }
      },
      callbackDown: () => {},
      callbackUp: (pointer: Phaser.Input.Pointer) => {
        this.player.liftoff();
        if (pointer.x <= this.cameras.main.centerX) { this.player.jump('left'); }
        if (pointer.x > this.cameras.main.centerX) { this.player.jump('right'); }
      },
    })

    // Audio 
    this.audio_player_hit = this.sound.add('audio_player_hit', { volume: 0.3 });
    this.audio_jump = this.sound.add('audio_jump', { volume: 0.3 });
    this.audio_dash = this.sound.add('audio_dash', { volume: 0.3 });
    this.audio_shield = this.sound.add('audio_shield', { volume: 0.3, loop: true });
    this.audio_drone_hit = this.sound.add('audio_drone_hit', { volume: 0.3 });
    this.audio_powerup_default = this.sound.add('audio_powerup_default', { volume: 0.3 });
    this.audio_powerup_heal = this.sound.add('audio_powerup_heal', { volume: 0.3 });
    this.audio_gameover = this.sound.add('audio_gameover', { volume: 0.2 });
    this.audio_highscore = this.sound.add('audio_highscore', { volume: 0.3 });

    // Collisions 
    this.setCollisions();
  }

  setCollisions() {
    // Player <--> World //
    this.physics.add.collider(this.player, [this.walls.wallLeft, this.walls.wallRight], player => {
      this.player.direction = this.player.direction * -1;
      this.player.avatar.setFlipX(this.player.direction < 0 ? false : true);
    });
    this.physics.add.collider(this.player, this.ground);

    // Player <--> Highscore Line 
    if (globalSaveFile.getHighscore() > 0) {
      this.physics.add.overlap(this.player, this.highscoreTracker.line, () => {
        this.highscoreTracker.kill();
      });
    }

    // Player <--> Electric Fence 
    this.physics.add.overlap(this.player, this.electricFence, () => {
      this.player.hitpoints = 0;
      this.sceneUserInterface.lives.loseAll();
      this.electricFence.hit();
      this.cameras.main.stopFollow();
      this.gameOver();
    });

    // Player <--> Enemies 
    this.physics.add.overlap(this.player, this.enemies, (player: unknown, enemy: unknown) => {
      const _player = player as Player
      const _enemy = enemy as EnemyBase

      _enemy.hit();

      if (!this.player.isInvulnerable) {
        _player.hit();
        this.sceneUserInterface.lives.lose();

        if(this.player.hitpoints <= 0){
          this.gameOver();
        }
      }
    })

    // Player <--> Powerups 
    this.physics.add.overlap(this.player, this.powerups, (player, powerup: unknown) => {
      const _powerup = powerup as Powerup
      _powerup.trigger();
    })
  }

  update() {
    // update input 
    this.inputManager.update()

    // Walls: move the physic body with camera 
    this.walls.setY(this.cameras.main.scrollY);

    // Electric Fence: update 
    this.electricFence.update();

    // Level Builder 
    this.levelBuilder.update();

    // Score & Day Cycle 
    const score = Helpers.getScoreFromPixels(this.player.y - this.cameras.main.height);

    if (score > parseInt(`${sessionSaveFile.getValue('totalScore')}`)) {
      // Score: Update score 
      const totalScore = sessionSaveFile.updateValue('totalScore', score);
      this.sceneUserInterface.scoreboard.update(score);

      // Highscore: Update highscore on better score
      if (globalSaveFile.getHighscore() < parseInt(`${totalScore}`)) {
        sessionSaveFile.updateValue('highscore', parseInt(`${totalScore}`));
      }

      // Day Cycle: Every X score progress the cycle 
      if (parseInt(`${totalScore}`) % Constants.DAYCYCLE_PROGRESS_PER_METER == 0) {
        this.dayCycle.progress();
      }
    }
  }

  saveData() {
    const saveData = this.game.registry.get("saveData") || {};

    //Copy last value & write new one
    if ("value" in saveData) {
      saveData["lastValue"] = saveData.value;
    }
    saveData["value"] = Phaser.Math.RND.integerInRange(0, 100000);

    //Send save event
    this.game.events.emit("save", saveData);
  }

  gameOver() {
    if (this.isGameOver) { return false; }
    this.isGameOver = true;

    this.player.death();
    this.audio_gameover.play();

    this.time.delayedCall(1200, () => {
      this.shutdown();
    });
  }

  shutdown() {
    const result = scoreController.getResult();
    
    //Transmit end
    this.game.events.emit('end', result);

    // Stop scene 
    this.scene.stop();
    const uiScene = this.scene.get("userinterface")
    uiScene.scene.stop()
  }
}
