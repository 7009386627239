import Phaser from "phaser";
import Constants from "../configs/constants";
import Scaling from "../configs/scaling";

import Lives from "../components/containers/lives";
import UIPowerups from "../components/containers/ui_powerups";
import Scoreboard from "../components/containers/scoreboard";

export default class UserInterface extends Phaser.Scene {
  params: any;
  scoreboard!: Scoreboard;
  lives!: Lives;
  powerups!: UIPowerups;
  
  constructor() {
    super({
      key: "userinterface"
    });
  }
  
  init(data: any){
    this.params = data;
  }

  create(){
    // UI: Score //
    this.scoreboard = new Scoreboard(this, Scaling.getPixelbyDPR(9), Scaling.getPixelbyDPR(62))

    // UI: Lives //    
    this.lives = new Lives(this, this.cameras.main.width - Scaling.getPixelbyDPR(24), Scaling.getPixelbyDPR(80), Constants.PLAYER_LIVES);

    // UI: Powerup //
    this.powerups = new UIPowerups(this, this.cameras.main.width - Scaling.getPixelbyDPR(8), this.lives.getBounds().bottom + Scaling.getPixelbyDPR(24), Constants.PLAYER_DASH_AMOUNT);
  }
  
}
