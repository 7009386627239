import Phaser from "phaser";

import EnemyStatic from "../sprites/enemies/enemyStatic";
import EnemyMoving from "../sprites/enemies/enemyMoving";
import EnemyBox from "../sprites/enemies/enemyBox";
import Main from "@/game/climber/states/main";
import IShadingSettings from "../../interfaces/DayCycle/IShadingSettings";
import scoreController from "@/utils/game/ScoreController";
import sessionSaveFile from "@/utils/game/SessionSaveFile";

export default class Enemies extends Phaser.GameObjects.Group {
  declare scene: Main;
  types: string[];
  constructor(scene: Phaser.Scene, amount: number) {
    super(scene);

    // Settings //
    this.types = [];

    // Enemy type: Static //
    this.types.push('enemyStatic');
    for(let index = 0; index < amount; index++){
      const enemy = new EnemyStatic(this.scene, 0, 0);
      this.add(enemy); 
    }

    // Enemy type: Moving //
    this.types.push('enemyMoving');
    for(let index = 0; index < amount; index++){
      const enemy = new EnemyMoving(this.scene, 0, 0);
      this.add(enemy);
    }   
    
    // Enemy type: Box //
    this.types.push('enemyBox');
    for(let index = 0; index < amount; index++){
      const enemy = new EnemyBox(this.scene, 0, 0);
      this.add(enemy);
    }       

    // Animations //
    this.scene.anims.create({ key: 'explode', frames: this.scene.anims.generateFrameNames('enemy_destroyed', { start:0, end: 5 }), hideOnComplete:true });

    // DayCycle: Add to cycle //
    this.getChildren().forEach(enemy => {
      this.scene.dayCycle.addSprite(enemy, {
        colors: {
          night: [0x20113D],
          day: [0xb5074c]
        },
        isFillTint: false
      })     
    })

  }

  spawn(x: number, y: number, type = 'none'){
    // Enemy: Get correct dead enemy from type //
    const pool = this.getMatching('name', type);
    const enemy = pool.find(enemy => enemy.active === false);

    // Tint: Set current DayCycle tint //
    const shadingObject = this.scene.dayCycle.shading.find((object: IShadingSettings) => object.shadingId === enemy.shadingId) as IShadingSettings;
    this.scene.dayCycle.updateTint(shadingObject, this.scene.dayCycle.cycle.start, this.scene.dayCycle.cycle.currentStep);

    // Enemy: Revive enemy with new ID //
    enemy ? enemy.revive(x, y) : null;

    // Stats: update //
    sessionSaveFile.incrementValue('enemiesSpawned', 1);
  }
}