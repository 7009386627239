import Phaser from "phaser";
import Scaling from "../../configs/scaling";
import * as Helpers from "../../configs/helpers";
import ICloudSpeed from "../../interfaces/ICloudSpeed";

export default class Cloud extends Phaser.GameObjects.Sprite {
  direction: number;
  speed: ICloudSpeed

  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y, '');
    this.scene.add.existing(this);

    // Settings //
    this.direction = 1;
    this.speed = {
      current:0,
      min:0.5,
      max:2
    };

    // Styling //
    this.setActive(false).setVisible(false);
  }

  kill(){
    this.setActive(false).setVisible(false);
  }

  revive(x: number, y: number){
    const direction = x < this.scene.cameras.main.centerX ? 'left' : 'right';

    // Direction: set directional movement //
    this.direction = direction === 'left' ? -1 : 1;

    // Direction: set styling based upon direction //
    this.setOrigin(direction === 'left' ? 0 : 1, 0.5);
    this.setFlipX(direction === 'left' ? true : false);
    this.setPosition(Phaser.Math.RND.between(0, this.scene.cameras.main.width), y);

    // Speed: randomize speed //
    this.speed.current = Phaser.Math.RND.realInRange(this.speed.min, this.speed.max);

    // Cloud: revive //
    this.setVisible(true).setActive(true);
  }  

  preUpdate(time: number, delta: number){
    super.preUpdate(time, delta);

    if(this.active){
      // Recycle: kill enemy if it moves below camera view //
      Helpers.kill(this);

      // Movement //
      this.x += this.speed.current * this.direction;
      if(this.direction < 0 && this.x < 0 - this.width){
        this.x = this.scene.cameras.main.width;
      }else if(this.direction > 0 && this.x > this.scene.cameras.main.width + this.width){
        this.x = 0;
      }
    }
  } 
}