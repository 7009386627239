import Main from "@/game/climber/states/main";
import sessionSaveFile from "@/utils/game/SessionSaveFile";
import Phaser from "phaser";
import Constants from "../../configs/constants";
import Scaling from "../../configs/scaling";

export default class Player extends Phaser.GameObjects.Container {
  direction: number;
  isInvulnerable: boolean;
  hitImmumeDuration: number
  hitpoints: number;
  dashAmount: number;

  particleHealth: Phaser.GameObjects.Particles.ParticleEmitterManager;
  particleHealthEmitter: Phaser.GameObjects.Particles.ParticleEmitter;
  
  avatar: Phaser.GameObjects.Sprite;
  jumpLines: Phaser.GameObjects.Sprite;
  shield: Phaser.GameObjects.Sprite;

  tweenDamage: Phaser.Tweens.Tween;
  tweenJumpLines: Phaser.Tweens.Tween;
  invulnerableTimer!: Phaser.Time.TimerEvent;
  
  declare body: Phaser.Physics.Arcade.Body;
  declare scene: Main
  constructor(scene: Phaser.Scene, x: number, y: number, hitpoints: number) {
    super(scene, x, y);
    this.scene.add.existing(this);

    // Settings //
    this.direction = this.getDirection('right');
    this.isInvulnerable = false;
    this.hitImmumeDuration = 2000;

    // Attributes //
    this.hitpoints = hitpoints;
    this.dashAmount = Constants.PLAYER_DASH_AMOUNT;
  
    // Scaling //
    this.setScale(0.7);

    // Particles: Health //
    this.particleHealth = this.scene.add.particles('icon_life_full')
    this.particleHealthEmitter = this.particleHealth.createEmitter({
      speed: { min: -400, max: 400 },
      angle: { min: 0, max: 360 },
      scale: { start: 0.8, end: 0 },
      blendMode: 'SCREEN',
      lifespan: 600,
      quantity:20
    });
    this.particleHealthEmitter.setAlpha(0).setPosition(this.x, this.y).explode(0, 0, 0);

    // Particles: Sonic boost beneat player //
     const particleSonicBoostConfig = {
      follow: this,
      speedY: 80,
      scale: { start: 0.1, end: 0.45 },
      alpha: { start: 1, end: 0 },
      blendMode: 'SCREEN',
      lifespan: 800,
      frequency:200,
      quantity:1
    };

    const particleSonicBoostLeft = this.scene.add.particles('particles_sonic').createEmitter({
      ...particleSonicBoostConfig,
      followOffset: {
        x:Scaling.getPixelbyDPR(-18),
        y:Scaling.getPixelbyDPR(28)
      }
    });

    const particleSonicBoostRight = this.scene.add.particles('particles_sonic').createEmitter({
      ...particleSonicBoostConfig,
      followOffset: {
        x:Scaling.getPixelbyDPR(7),
        y:Scaling.getPixelbyDPR(28)
      }
    });    

    // Avatar //
    this.avatar = this.scene.add.sprite(0, 0, 'player');
    this.add(this.avatar);

    // Avatar: JumpLines //
    this.jumpLines = this.scene.add.sprite(0, 0, 'player_jump_lines').setAlpha(0);
    this.add(this.jumpLines);

    // Shield //
    this.shield = this.scene.add.sprite(0, -Scaling.getPixelbyDPR(10), 'player_shield').setActive(false).setVisible(false);
    this.add(this.shield);

    // Physics //
    this.scene.physics.world.enable(this);
    this.body.setCollideWorldBounds(false);
    this.body.setDamping(true);
    this.body.setDrag(0.4, 0.4);
    this.body.setSize(this.avatar.width/2, this.avatar.height - Scaling.getPixelbyDPR(25));
    this.body.setOffset(-Scaling.getPixelbyDPR(24), -(this.avatar.height/2));
    this.body.setGravityY(0);
    this.body.setBounce(0.5, 0.5);

    // Animations //
    this.tweenDamage = this.scene.tweens.add({
      targets:this,
      paused:true,
      alpha: 0.2,
      duration:150,
      yoyo:true,
      repeat:-1,
      onStop:() => this.setAlpha(1)
    })

    this.tweenJumpLines = this.scene.tweens.add({
      targets:this.jumpLines,
      paused:true,
      alpha: {from:1, to:0},
      y: `+=${Scaling.getPixelbyDPR(80)}`,
      duration:200,
      onStop:() => this.setY(0).setAlpha(0)
    })    

    const tweenShield = this.scene.tweens.add({
      targets:this.shield,
      y: `-=${Scaling.getPixelbyDPR(5)}`,
      scale: 0.9,
      duration:200,
      yoyo:true,
      repeat:-1
    })       
  }

  getDirection(direction: string){
    return direction === 'left' ? -1 : 1;
  }  

  liftoff(){
    this.body.setGravityY(500);
  }

  jump(direction: string){
    if(this.hitpoints > 0){
      
      this.scene.audio_jump.play();

      this.direction = direction ? this.getDirection(direction) : this.direction * -1;
      
      this.avatar.setFlipX(this.direction < 0 ? true : false);
      this.body.setVelocityY(-(600 * (Scaling.GAME_BASE_DIFF_HEIGHT > 1 ? Scaling.GAME_BASE_DIFF_HEIGHT * 1.4 : 1)));
      this.body.setVelocityX(this.direction * 500);

      // Animation: Jump lines //
      this.tweenJumpLines.isPlaying() ? this.tweenJumpLines.restart() : this.tweenJumpLines.play();

      // Stats: update //
      sessionSaveFile.incrementValue('jumps', 1);
    }
  }

  dash(){
    if(this.dashAmount > 0 && this.hitpoints >= 0){
      
      this.scene.audio_dash.play();

      this.dashAmount--;
      this.scene.sceneUserInterface.powerups.used();

      this.setShield(2800);
      this.body.setVelocityY(-2000);

      // Animation: Jump lines //
      this.tweenJumpLines.isPlaying() ? this.tweenJumpLines.restart() : this.tweenJumpLines.play();      
    }
  }

  hit(){
    
    this.scene.audio_player_hit.play();

    this.hitpoints--;
    this.tweenDamage.isPlaying() ? this.tweenDamage.restart() : this.tweenDamage.play();
    this.setInvulnerable(this.hitImmumeDuration, () => this.tweenDamage.stop());
  }
  
  death(){ 
    this.scene.audio_shield.stop();

    this.body.setAngularVelocity(400);
    this.body.setGravityY(900);
  }

  dashRecover(){
    if(this.dashAmount !== Constants.PLAYER_DASH_AMOUNT){
      this.dashAmount++;
      
      this.scene.sceneUserInterface.powerups.recover();
    }
  }

  hitpointsRecover(){
    if(this.hitpoints !== Constants.PLAYER_LIVES){
      this.hitpoints++;  
      
      this.scene.sceneUserInterface.lives.recover();
    }
    
    this.particleHealthEmitter.setAlpha(1).setPosition(this.x, this.y).explode(20, this.x, this.y);
  }

  setShield(duration: number){
    
    this.scene.audio_shield.play();
    this.tweenDamage.isPlaying() ? this.tweenDamage.stop() : null;
    
    this.shield.setActive(true).setVisible(true);
    this.setInvulnerable(duration, () => {
      this.scene.audio_shield.stop();
      this.shield.setActive(false).setVisible(false)
    });
  }

  setInvulnerable(time: number, onCompleteFunction: () => void){
    this.isInvulnerable = true;
    this.invulnerableTimer ? this.invulnerableTimer.remove() : null;
    this.invulnerableTimer = this.scene.time.delayedCall(time, () => {
      this.isInvulnerable = false;
      if(typeof onCompleteFunction == 'function'){
        onCompleteFunction();
      }
    });
  }
}