import Phaser from "phaser";
import Scaling from "../configs/scaling";
import Constants from "../configs/constants";
import Background from "./background";
import Setup from "@/helpers/Setup";

export default class Load extends Phaser.Scene {
  background!: Phaser.GameObjects.Image;
  logoOutline!: Phaser.GameObjects.Image;
  logo!: Phaser.GameObjects.Image;
  
  constructor() {
    super({
      key: "load"
    });
  }
  
  preload() {
    // Logo //
    this.logoOutline = this.add.image(this.cameras.main.centerX, this.cameras.main.centerY + Scaling.getPixelbyDPR(30), "logo_outline");    
    this.tweens.add({
      targets: this.logoOutline,
      scale:1.1,
      alpha:0,
      repeat: -1,
      duration:800,
      ease: "Power1"
    });    
    this.logo = this.add.image(this.cameras.main.centerX, this.cameras.main.centerY + Scaling.getPixelbyDPR(30), "logo");    
    const logoBounds = this.logo.getBounds();

    // Loader //
    const loaderWidth = 300 * Scaling.DPR;
    const loaderBase = this.add.graphics({
      x: logoBounds.centerX,
      y: logoBounds.bottom + (56 * Scaling.DPR)
    });
    loaderBase.fillStyle(0x000000, 0.1);
    loaderBase.fillRect(-(loaderWidth) / 2, 0, loaderWidth, 12 * Scaling.DPR);
    
    const loaderLoaded = this.add.graphics({
      x: logoBounds.centerX - ((loaderWidth) / 2),
      y: logoBounds.bottom + (56 * Scaling.DPR)
    });
    loaderLoaded.fillStyle(0xFFFFFF, 1);
    loaderLoaded.fillRect(0, 0, 0, 12 * Scaling.DPR);
    
    // Progression //
    this.load.on("progress", (value: number) => {
      loaderLoaded.clear();
      loaderLoaded.fillStyle(0xFFFFFF, 1);
      loaderLoaded.fillRect(0, 0, (loaderWidth * value), 12 * Scaling.DPR);
    });
 
    // Data //
    this.load.json("json_layouts_obstacles", "data/games/climber/obstacle_layouts.json");
    this.load.json("json_layouts_background", "data/games/climber/background_layouts.json");
    
    // Backgrounds //
    this.load.image("skyline_start", Scaling.imagePath("background_skyline_start", "png"));
    this.load.image("skyline_one", Scaling.imagePath("background_skyline_1", "png"));
    this.load.image("skyline_two", Scaling.imagePath("background_skyline_2", "png"));
    this.load.image("skyline_three", Scaling.imagePath("background_skyline_3", "png"));
    this.load.image("skyline_four", Scaling.imagePath("background_skyline_4", "png"));
    this.load.image("object_beam", Scaling.imagePath("background_lightarray", "png"));
    this.load.image("cloud_extrasmall", Scaling.imagePath("background_cloud_extrasmall", "png"));
    this.load.image("cloud_small", Scaling.imagePath("background_cloud_small", "png"));
    this.load.image("cloud_medium", Scaling.imagePath("background_clouds_medium", "png"));
    this.load.image("sun", Scaling.imagePath("background_sun", "png"));
    this.load.image("moon", Scaling.imagePath("background_moon", "png"));
        
    // Icons //
    this.load.image("icon_coin", Scaling.imagePath("icon_coins", "png"));
    this.load.image("icon_life_empty", Scaling.imagePath("icon_life_empty", "png"));
    this.load.image("icon_life_full", Scaling.imagePath("icon_life_fill", "png"));
    
    // Interface //
    this.load.image("ui_coin_background", Scaling.imagePath("interface_texture_black", "png"));
    this.load.image('powerup_ui_filled', Scaling.imagePath('powerups_ui_filled', 'png'))
    this.load.image('powerup_ui_empty', Scaling.imagePath('powerups_ui_empty', 'png'))

    // Sprites //
    this.load.image("player", Scaling.imagePath("player", "png"));
    this.load.image("player_shield", Scaling.imagePath("sprite_shield", "png"));

    this.load.image("player_jump_lines", Scaling.imagePath("sprite_boost", "png"));
    this.load.image("particles_sonic", Scaling.imagePath("sprite_sonic", "png"));
    
    this.load.image("particles_coin", Scaling.imagePath("sprite_coin", "png"));

    this.load.image("enemy_box_left", Scaling.imagePath("sprite_box1_left", "png"));
    this.load.image("enemy_box_right", Scaling.imagePath("sprite_box1_right", "png"));
    this.load.image("enemy_box_two_left", Scaling.imagePath("sprite_box2_left", "png"));
    this.load.image("enemy_box_two_right", Scaling.imagePath("sprite_box2_right", "png"));    
    
    this.load.image("sign_big", Scaling.imagePath("background_sign", "png"));
    this.load.image("sign_small_dark", Scaling.imagePath("sprite_sign_night", "png"));
    this.load.image("sign_small_light", Scaling.imagePath("sprite_sign_day", "png"));
    this.load.image("sign_small_middle", Scaling.imagePath("sprite_sign_noon", "png"));
    this.load.image("object_arch", Scaling.imagePath("sprite_arch", "png"));
    this.load.image("object_pole", Scaling.imagePath("sprite_sign_large", "png"));
    
    // Spritesheets //
    this.load.spritesheet("enemy_static", Scaling.imagePath('spritesheet_enemy_static','png'), {frameWidth: 99 * Scaling.DPR, frameHeight: 70 * Scaling.DPR});
    this.load.spritesheet("enemy_moving", Scaling.imagePath('spritesheet_enemy_moving','png'), {frameWidth: 112 * Scaling.DPR, frameHeight: 34 * Scaling.DPR});
    this.load.spritesheet("enemy_static_small", Scaling.imagePath('spritesheet_enemy_static_small','png'), {frameWidth: 56 * Scaling.DPR, frameHeight: 28 * Scaling.DPR});
    this.load.spritesheet("enemy_destroyed", Scaling.imagePath('spritesheet_enemy_explode','png'), {frameWidth: 65 * Scaling.DPR, frameHeight: 70 * Scaling.DPR});
    this.load.spritesheet("powerups", Scaling.imagePath("spritesheet_powerups", "png"), {frameWidth: 64 * Scaling.DPR, frameHeight: 64 * Scaling.DPR});
    
    // Tilesprites //
    this.load.image("wall", Scaling.imagePath("tilesprite_wall", "png"));
    this.load.image("floor", Scaling.imagePath("tilesprite_groundfloor", "png"));
    this.load.image("object_electric_fence", Scaling.imagePath("tilesprite_fence", "png"));
    this.load.image("highscore_line", Scaling.imagePath("tilesprite_highscore", "png"));

    // Load sounds //
    this.load.audio("background-music", ["sounds/games/climber/background.mp3"]);
    this.load.audio("button-confirm", ["sounds/games/climber/button-default.mp3"]);
    this.load.audio("audio_jump", ["sounds/games/climber/jump.mp3"]);
    this.load.audio("audio_drone_hit", ["sounds/games/climber/drone-hit.mp3"]);
    this.load.audio("audio_player_hit", ["sounds/games/climber/player-hit.mp3"]);
    this.load.audio("audio_dash", ["sounds/games/climber/dash.mp3"]);
    this.load.audio("audio_shield", ["sounds/games/climber/shields.mp3"]);
    this.load.audio("audio_powerup_default", ["sounds/games/climber/powerup_default.mp3"]);
    this.load.audio("audio_powerup_heal", ["sounds/games/climber/powerup_heal.mp3"]);
    this.load.audio("audio_gameover", ["sounds/games/climber/gameover.mp3"]);
    this.load.audio("audio_highscore", ["sounds/games/climber/highscore.mp3"]);

    // Completion //
    this.load.on("complete", () => {
      this.time.addEvent({
        delay: 1500,
        callback: () => {
          // Loader: Remove //
          loaderBase.destroy();
          loaderLoaded.destroy();
          
          // Loader text //
          const proceedText = this.add.text(this.cameras.main.centerX, logoBounds.bottom + Scaling.getPixelbyDPR(56), Setup.getCopy("general.touchStart"), {
            fixedWidth: this.scene.systems.canvas.width,
            align: "center",
            fontFamily: Constants.FONT_BOLD,
            fontSize: `${Scaling.getPixelbyDPR(18)}px`,
            color: "#ffffff"
          }).setOrigin(0.5);
          
          // Loader text: Tween //
          this.tweens.add({
            targets: proceedText,
            scale: {
              to:1.1,
              from:1
            },
            yoyo: true,
            repeat: -1,
            duration: 1000,
            ease: "Power1"
          });
          
          // Events: Click //
          this.input.on("pointerup", () => {
            this.input.off("pointerup");
            
            // Sounds: Play //
            const audio_button = this.sound.add('button-confirm', {volume:0.1});
            audio_button.play();
            
            const backgroundScene = this.scene.get("background") as Background;
            if(backgroundScene) {
              backgroundScene.playMusic();
            }
            
            // Game event: Loaded //
            this.game.events.emit("loaded");
          });
        }
      });
    });    
  }
}


