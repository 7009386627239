import Phaser from "phaser";
import Scaling from "../../configs/scaling";
import Wall from "../sprites/wall";

export default class Walls extends Phaser.GameObjects.Container {
  wallLeft: Wall;
  wallRight: Wall;

  
  constructor(scene: Phaser.Scene, x: number, y: number, width: number) {
    super(scene, x, y);
    this.scene.add.existing(this);

    this.wallLeft = new Wall(this.scene, 0, 0, {
      width: width,
      origin: [0,0]
    })

    this.wallRight = new Wall(this.scene, this.scene.cameras.main.width, 0, {
      width: width,
      origin: [1,0]
    })

    this.add([this.wallLeft, this.wallRight]);
  }
}