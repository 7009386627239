import Main from "@/game/climber/states/main";
import EnemyBase from "./enemyBase";

export default class EnemyMoving extends EnemyBase {
  direction: number;
  speed: number;
  maxAngle: number;
  canMove: boolean;

  declare scene: Main;
  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y, 'enemy_moving');
   

    // Type //
    this.setName('enemyMoving');

    // Settings //
    this.direction = this.getDirection('left');
    this.speed = 4;
    this.maxAngle = 5;
    this.canMove = true;

    // Modes //
    this.modes.attack.frame = 0;
    this.modes.dormant.frame = 1;
  }

  getDirection(direction: string){
    return direction === 'left' ? -1 : 1;
  }

  revive(x: number, y: number){
    super.revive(x, y);
    this.setTexture('enemy_moving');
    this.canMove = true;
  }

  hit(){
    super.hit();
    this.canMove = false;
  }

  preUpdate(time: number, delta: number){
    super.preUpdate(time, delta);

    if(this.active && this.canMove){
      // Movement: Direction //
      this.x += this.speed * this.direction;
      if(this.getLeftCenter().x < this.scene.worldBoundLeft){
        this.direction = this.getDirection('right');
      }else if(this.getRightCenter().x > this.scene.worldBoundRight){
        this.direction = this.getDirection('left');
      }

      // Movement: Angle //
      this.angle += 0.4 * this.direction;
      if(Math.abs(this.angle) >= this.maxAngle){
        this.angle = this.maxAngle * this.direction;
      }
    }
  }
}