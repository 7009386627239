import Phaser from "phaser";
import * as Helpers from "../../configs/helpers";
import IPowerupType from "../../interfaces/IPowerupType";

export default class Powerup extends Phaser.GameObjects.Sprite {
  declare body: Phaser.Physics.Arcade.Body;
  tweenPulse: Phaser.Tweens.Tween;
  triggerFunction: any;

  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y, 'powerups');
    this.scene.add.existing(this);

    // Styling //
    this.setActive(false).setVisible(false);

    // Physics //
    this.scene.physics.world.enable(this);
    this.body.setEnable(false);    

    // Animations //
    this.tweenPulse = this.scene.tweens.add({
      targets:this,
      paused:true,
      scale: 0.9,
      duration:600,
      yoyo:true,
      repeat:-1,
      ease:'Power1'
    })    
  }

  setType(type: IPowerupType){
    this.triggerFunction = type.onTriggerFunction;
    this.setFrame(type.frame);
  }

  trigger(){
    this.triggerFunction();
    this.kill();
  }

  kill(){
    this.tweenPulse.stop();

    this.body.setEnable(false);
    this.setActive(false).setVisible(false);
  }

  revive(x: number , y: number ){
    this.setPosition(x, y);
    
    this.tweenPulse.play();

    this.body.setEnable(true);
    this.setVisible(true).setActive(true);
  }  

  preUpdate(time: number, delta: number){
    super.preUpdate(time, delta);

    if(this.active){
      // Recycle: kill enemy if it moves below camera view //
      Helpers.kill(this);
    }
  } 
}