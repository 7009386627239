import Phaser from "phaser";
import ISignSetting from "../../interfaces/ISignSetting";
import Main from "../../states/main";
import Sign from "../sprites/sign";

export default class Signs extends Phaser.GameObjects.Group {
  declare scene: Main;
  types: ISignSetting
  constructor(scene: Phaser.Scene, amount: number) {
    super(scene);

    this.types = {
      big: 'sign_big',
      small: {
        dark:'sign_small_dark',
        middle:'sign_small_middle',
        light:'sign_small_light'
      }
    }

    for(let index = 0; index < amount; index++){
      const sign = new Sign(this.scene, 0, 0);
      this.add(sign); 
    }

  }

  spawn(x: number, y: number, type = 'small'){
    const sign= this.getFirstDead() as Sign;

    // Texture //
    if(type === 'small'){
      const dayCycleProgress = this.scene.dayCycle.getProgression();
      if(dayCycleProgress > 20 && dayCycleProgress < 65){
        sign.setTexture(this.types.small.middle);    
      }else if(dayCycleProgress >= 65){
        sign.setTexture(this.types.small.light);    
      }else {
        sign.setTexture(this.types.small.dark);    
      }
    }else {
      sign.setTexture(type);
    }

    // Sign: spawn //
    sign ? sign.revive(x, y, type) : null;
  }
}