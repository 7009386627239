import EnemyBase from "./enemyBase";

export default class EnemyStatic extends EnemyBase {

  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y, 'enemy_static');

    // Type //
    this.setName('enemyStatic');

  }

  revive(x: number, y: number){
    super.revive(x, y);
    this.setTexture('enemy_static');
  }  
}