import Phaser from "phaser";
import ShakePositionPlugin from 'phaser3-rex-plugins/plugins/shakeposition-plugin.js';
import Boot from "./states/boot";
import Background from "./states/background";
import Load from "./states/load";
import Main from "./states/main";
import UserInterface from "./states/userinterface";
import Debug from "./states/debug";
import Scaling from "./configs/scaling";

export default class Game {

  private gameInstance: Phaser.Game;
  private loaded = false;

  constructor(parentWidth: number, parentHeight: number) {
    // Adjust game width/height if resolution ratio is different from base //
    Scaling.updateResolutionRatio(parentWidth, parentHeight);

    //Construct config
    const config: Phaser.Types.Core.GameConfig = {
      type: Phaser.AUTO,
      width: Scaling.GAME_WIDTH,
      height: Scaling.GAME_HEIGHT,
      scale: {
        parent: "game",
        zoom: 1 / Scaling.DPR,
        width: Scaling.GAME_WIDTH * Scaling.DPR,
        height: Scaling.GAME_HEIGHT * Scaling.DPR,
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH
      },
      physics: {
        default: "arcade",
        arcade: {
          debug: false
        }
      },
      fps: {
        target: 60,
        forceSetTimeOut: false
      },
      render: {
        antialias: true,
        roundPixels: true,
        pixelArt: false
      },
      plugins: {
        global: [{
          key: 'rexShakePosition',
          plugin: ShakePositionPlugin,
          start: true
        }]
      },
      scene: [
        Boot,
        Background,
        Load,
        Main,
        UserInterface,
        Debug
      ]
    };

    //Create and run game
    this.gameInstance = new Phaser.Game(config);
  }

  // Events
  setEventListener(name: string, listener?: Function) {
    this.removeEventListener(name);

    // Only set, if actually provided
    if (listener) {
      this.gameInstance.events.on(name, listener);
    }
  }

  removeEventListener(name: string) {
    this.gameInstance.events.off(name);
  }

  // (Re)start game
  startGame(data?: any) {
    const scene = this.gameInstance.scene.getScene("game");
    if (scene) {
      if (data) {
        if (data.toMap) {
          scene.scene.start('game', { toMap: data.toMap });
        }
        if (data.toNextLevel) {
          scene.scene.start('game', { level: data.toNextLevel });
        }
      } else {
        scene.scene.start();
      }
    }
  }

  // Stop game
  stopGame() {
    const scene = this.gameInstance.scene.getScene("game");
    if (scene) {
      scene.scene.stop();
    }
  }

  // Start loading
  loadGame() {
    if (!this.loaded) {
      this.startScene("load");
      this.loaded = true;
    }
  }

  // Start a scene in the game
  startScene(key: string) {
    const scene = this.gameInstance.scene.getScene(key);
    if (scene) {
      scene.scene.start();
    }
  }

  // Stop scene in the game
  stopScene(key: string) {
    const scene = this.gameInstance.scene.getScene(key);
    if (scene) {
      scene.scene.stop();
    }
  }

  // Hide the loading view
  endLoad() {
    if (this.loaded) {
      this.stopScene("load");
    }
  }

  // Set background color
  setBackgroundColor(color: number) {
    const scene = this.gameInstance.scene.getScene("background") as any;
    if (scene) {
      scene.setColor(color);
    }
  }

  //Setters
  setSaveData(data: any) {
    this.gameInstance.registry.set("saveData", data);
  }

  // Used to destroy phaser instance
  destroy() {
    this.gameInstance.destroy(true);
  }
}