import Main from "@/game/climber/states/main";
import Phaser from "phaser";
import Scaling from "../../configs/scaling";

export default class Wall extends Phaser.GameObjects.Sprite {
  declare body: Phaser.Physics.Arcade.Body;
  declare scene: Main;
  wallTileSprite: Phaser.GameObjects.TileSprite;
  
  constructor(scene: Phaser.Scene, x: number, y: number, settings: { width: any; origin: any; }) {
    super(scene, x, y, "");

    this.scene.add.existing(this);

    // Visual representation of the wall //
    this.wallTileSprite = this.scene.add.tileSprite(x, y, settings.width, this.scene.cameras.main.height, 'wall').setOrigin(settings.origin[0], settings.origin[1]).setScrollFactor(0);

    // Texture //
    const texture = this.generateTexture(settings.width);
    
    // Style //
    this.setTexture(texture);    
    this.setOrigin(settings.origin[0], settings.origin[1]);

    // Physics //
    this.scene.physics.world.enable(this);    
    this.body.setImmovable(true);

    // DayCycle: Add to cycle //
    this.scene.dayCycle.addSprite(this.wallTileSprite, {
      colors: {
        night:[0x331C5C],
        day:[0xbf1558]
      },
      isFillTint:true
    })        
  }

  generateTexture(width: number){
    if(!this.scene.textures.exists('wallPhysics')){
      const textureBackground = this.scene.make.graphics({x:0, y:0, add:false});
      textureBackground.fillStyle(0xffffff , 0);
      textureBackground.fillRect(0, 0, width, this.scene.cameras.main.height);
      textureBackground.generateTexture('wallPhysics', width, this.scene.cameras.main.height);
    }

    return 'wallPhysics';
  }  
}