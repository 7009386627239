import Main from "@/game/climber/states/main";
import Constants from "../../configs/constants";
import ICycle from "../../interfaces/ICycle";
import IShadingSettings from "../../interfaces/DayCycle/IShadingSettings";
import ISpriteSetting from "../../interfaces/ISpriteSettings";
import IDayCycleSettings, { IDayNightSetting } from "../../interfaces/DayCycle/IDayCycleSettings";
import sessionSaveFile from "@/utils/game/SessionSaveFile";

export default class DayCycle {
  scene: Main;
  cycle: ICycle;
  finished: boolean;
  shadingId: number;
  shading: IShadingSettings[];  
  sun: IDayNightSetting
  moon: IDayNightSetting

  constructor(scene: Main, settings: IDayCycleSettings){
    this.scene = scene;

    // Settings //
    this.cycle = {
      start: settings.cycle.start ? settings.cycle.start : 'day',
      currentStep:0,
      totalSteps: settings.cycle.steps ? settings.cycle.steps : 100
    }
    this.finished = false;
    this.shadingId = 0;
    this.shading = [];

    // Sun //
    this.sun = settings.sun

    // Moon //
    this.moon = settings.moon

    this.setConfig();
  }

  setConfig(){
    this.sun.sprite.setY( this.cycle.start === 'day' ? this.sun.position.high : this.sun.position.low );
    this.moon.sprite.setY( this.cycle.start === 'day' ? this.moon.position.low: this.moon.position.high );
  }

  // too many different types of values get passed so i left it at any
  addSprite(sprite: any, settings: ISpriteSetting){
    // ID: increment indentifier and attach to sprite //
    this.shadingId++;

    sprite.shadingId = this.shadingId;

    // Shading: combine sprite / unique id and settings //
    const object: IShadingSettings = {
      sprite:sprite,
      shadingId: this.shadingId,
      ...settings
    };
    
    // Shading: add sprite object to shader update array //
    this.shading.push(object);
  }  

  getProgression(){
    return (this.cycle.currentStep / this.cycle.totalSteps) * 100;
  }

  
  progress(){
    if(this.cycle.currentStep != this.cycle.totalSteps){
      this.cycle.currentStep++;
      
      // Update all object tints //
      if(this.shading){
        this.shading.forEach((object) => {
          if(object.sprite.active){
            this.updateTint(object, this.cycle.start, this.cycle.currentStep);
          }
        });
      }
      
      // Update Sun and Moon positions //
      this.updateStellarObjects();

      // Stats: update //
      sessionSaveFile.incrementValue('dayCycleProgress', 1);
    }else {
      this.finished = true;
    }
  }

  updateStellarObjects(){
    const sunPosition = (Math.abs(this.sun.position.high - this.sun.position.low) / this.cycle.totalSteps);
    const sunDirection = this.cycle.start === 'day' ? 1 : -1;
    const sunTween = this.scene.tweens.add({
      targets:this.sun.sprite,
      y:this.sun.sprite.y + (sunPosition * sunDirection),
      duration:Constants.DAYCYCLE_CELESTIAL_TWEEN_SPEED,
      ease:'Cubic.InOut'
    })

    const moonPosition = (Math.abs(this.moon.position.high - this.moon.position.low) / this.cycle.totalSteps);
    const moonDirection = this.cycle.start === 'day' ? -1 : 1;
    const moonTween = this.scene.tweens.add({
      targets:this.moon.sprite,
      y:this.moon.sprite.y + (moonPosition * moonDirection),
      duration:Constants.DAYCYCLE_CELESTIAL_TWEEN_SPEED,
      ease:'Cubic.InOut'
    })    
  }

  // added .sprite to everything because of the interface
  updateTint(object: IShadingSettings, cycle: string, step: number){
    const sprite = object.sprite;
    const steps = this.cycle.totalSteps;
    const startColor = cycle === 'day' ? object.colors.day : object.colors.night;
    const endColor = cycle === 'day' ? object.colors.night : object.colors.day;
    const isFillTint = object.isFillTint as boolean;
    const hasGradient = object.sprite.hasGradient;

    const colorFrom = Phaser.Display.Color.IntegerToColor(startColor[0]);
    const colorTo = Phaser.Display.Color.IntegerToColor(endColor[0]);
    const colorFrom2 = hasGradient ? Phaser.Display.Color.IntegerToColor(startColor[1]) : undefined;
    const colorTo2 = hasGradient ? Phaser.Display.Color.IntegerToColor(endColor[1]) : undefined;

    const colorRGB = Phaser.Display.Color.Interpolate.ColorWithColor(colorFrom, colorTo, steps, step);
    const colorHex = Phaser.Display.Color.RGBToString(colorRGB.r, colorRGB.g, colorRGB.b);
    let colorHex2 

    if(colorFrom2 && colorTo2) {
      const colorRGB2 = hasGradient ? Phaser.Display.Color.Interpolate.ColorWithColor(colorFrom2, colorTo2, steps, step) : undefined;

      if(colorRGB2) {
        colorHex2 = hasGradient ? Phaser.Display.Color.RGBToString(colorRGB2.r, colorRGB2.g, colorRGB2.b) : undefined;
      }
    }

    this.applyTint(sprite, colorHex, isFillTint, hasGradient, colorHex2);
  }


  applyTint(sprite: Phaser.GameObjects.Sprite, colorHex: string, isFillTint: boolean, hasGradient: boolean, colorHex2?: string){
    if(isFillTint){
      if(hasGradient && colorHex2 !== undefined){
        sprite.setTintFill(parseInt(colorHex.replace('#', '0x')), parseInt(colorHex.replace('#', '0x')), parseInt(colorHex2.replace('#', '0x')), parseInt(colorHex2.replace('#', '0x')));
      }else {
        sprite.setTintFill(parseInt(colorHex.replace('#', '0x')));
      }
    }else {
      if(hasGradient && colorHex2 !== undefined){
        sprite.setTint(parseInt(colorHex.replace('#', '0x')), parseInt(colorHex.replace('#', '0x')), parseInt(colorHex2.replace('#', '0x')), parseInt(colorHex2.replace('#', '0x')));
      }else {
        sprite.setTint(parseInt(colorHex.replace('#', '0x')));
      }
    }
  }
}