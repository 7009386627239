import Phaser from "phaser";
import Skyline from "../sprites/skyline";

export default class Skylines extends Phaser.GameObjects.Group {
  types: string[];
  startExclusive: string;
  
  constructor(scene: Phaser.Scene, amount: number) {
    super(scene);

    this.types = ['skyline_one','skyline_two','skyline_three','skyline_four'];
    this.startExclusive = 'skyline_start';

    for(let index = 0; index < amount; index++){
      const skyline = new Skyline(this.scene, 0, 0);
      this.add(skyline); 
    }

  }

  spawn(y: number, isStart: boolean){
    const skyline = this.getFirstDead();

    // Texture: set random, except for starting skyline //
    skyline.setTexture(isStart ? this.startExclusive : Phaser.Math.RND.pick(this.types));
    
    // Skyline: spawn //
    skyline ? skyline.revive(y) : null;
  }
}