import main from "@/game/climber/states/main";
import Phaser, { Physics } from "phaser";
import Constants from "../../configs/constants";
import Scaling from "../../configs/scaling";

export default class HighscoreTracker extends Phaser.GameObjects.Container {
  particleReached: Phaser.GameObjects.Particles.ParticleEmitterManager;
  particleEmitter: Phaser.GameObjects.Particles.ParticleEmitter;
  line: Phaser.GameObjects.TileSprite;

  declare scene: main
  constructor(scene: main, x: number, y: number, score: number) {
    super(scene, x, y);
    this.scene.add.existing(this);

    // Particles //
    this.particleReached = this.scene.add.particles('particles_coin')
    this.particleReached.setName("particle_manager")
    this.particleEmitter = this.particleReached.createEmitter({
      speed: { min: -400, max: 400 },
      angle: { min: 0, max: 360 },
      scale: { start: 0.8, end: 0 },
      blendMode: 'SCREEN',
      lifespan: 600,
      quantity:20
    });
    this.particleEmitter.setAlpha(0).setPosition(0,Scaling.getPixelbyDPR(12)).explode(0, 0, 0);

    // Line //
    this.line = this.scene.add.tileSprite(0, 0, this.scene.cameras.main.width, Scaling.getPixelbyDPR(12), 'highscore_line');
    this.line.setOrigin(0, 1);

    this.scene.physics.world.enable(this.line);
    const bodyLine = this.line.body as Phaser.Physics.Arcade.Body
    bodyLine.setImmovable(true)    
    
    // Text //
    const textNumber = this.scene.add.text(this.scene.cameras.main.centerX, this.line.getBounds().bottom + Scaling.getPixelbyDPR(8), `${score}`, {
      fontFamily: Constants.FONT_BOLD,
      fontSize: `${Scaling.getPixelbyDPR(18)}px`,
      align: "center",
      color: "#ffffff"
    }).setOrigin(0.5, 0);

    const textMeter = this.scene.add.text(textNumber.getBounds().right + Scaling.getPixelbyDPR(6), textNumber.getBounds().bottom - Scaling.getPixelbyDPR(2), `M`, {
      fontFamily: Constants.FONT_BOLD,
      fontSize: `${Scaling.getPixelbyDPR(12)}px`,
      align: "center",
      color: "#ffffff"
    }).setOrigin(0, 1);  

    // Container: add childs //
    this.add([this.line, textNumber, textMeter, this.particleReached]);
  }

  kill(){
    const body = this.line.body as Phaser.Physics.Arcade.Body
    body.setEnable(false)

    this.scene.audio_highscore.play();
    
    this.particleEmitter.setAlpha(1);
    for(let index = 0; index <= 10; index++){
      this.particleEmitter.explode(20, this.scene.cameras.main.width/10*index, 0);
    }

    this.each((item: Phaser.GameObjects.TileSprite | Phaser.GameObjects.Text) => { 
      if(item.name !== "particle_manager") {
        item.setActive(false).setVisible(false);  
      }
    })
  }
}