import Phaser from "phaser";
import Scaling from "../../configs/scaling";
import * as Helpers from "../../configs/helpers";

export default class Skyline extends Phaser.GameObjects.Sprite {
  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y, 'skyline_start');
    this.scene.add.existing(this);

    // Styling //
    this.setScale(Scaling.GAME_BASE_DIFF_HEIGHT);
    this.setOrigin(0.5, 1);
    this.setActive(false).setVisible(false);
  }

  kill(){
    this.setActive(false).setVisible(false);
  }

  revive(y: number){
    this.setPosition(this.scene.cameras.main.centerX, y);
    this.setVisible(true).setActive(true);
  }  

  preUpdate(time: number, delta: number){
    super.preUpdate(time, delta);

    if(this.active){
      // Recycle: kill enemy if it moves below camera view //
      Helpers.kill(this);
    }
  } 
}