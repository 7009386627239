import Phaser from "phaser";
import Scaling from "../../../configs/scaling";
import * as Helpers from "../../../configs/helpers";
import IEnemyModes from "@/game/climber/interfaces/IEnemyModes";
import Main from "@/game/climber/states/main";

export default class EnemyBase extends Phaser.GameObjects.Sprite {
  modes: IEnemyModes

  declare body: Phaser.Physics.Arcade.Body;
  declare scene: Main;
  constructor(scene: Phaser.Scene, x: number, y: number, sprite: string) {
    super(scene, x, y, sprite);
    this.scene.add.existing(this);

    // Modes //
    this.modes = {
      current:'dormant',
      dormant:{
        frame:0,
      },
      attack:{
        frame:1,
        radius:Scaling.getPixelbyDPR(200)
      }
    }

    // Styling //
    this.setScale(0.6);
    this.setActive(false).setVisible(false);

    // Physics //
    this.scene.physics.world.enable(this);
    this.body.setEnable(false);

    // Animation events //
    // not called anywhere
    this.on('animationcomplete', (e: any) => {
      if(e.key === 'explode'){ this.kill(); }
    });
  }

  setMode(mode: string){
    this.modes.current = mode;

    switch(this.modes.current){
      case 'attack':
        this.setFrame(this.modes.attack.frame);
        break;
      case 'dormant':
        this.setFrame(this.modes.dormant.frame);
        break;
    }
  }  

  hit(){
    this.play('explode');
    this.scene.audio_drone_hit.play();
    
    this.body.setEnable(false);
  }

  kill(){
    this.body.setEnable(false);
    this.setActive(false).setVisible(false);
  }

  revive(x: number, y: number){
    this.setPosition(x, y);
    
    this.body.setEnable(true);
    this.setVisible(true).setActive(true);
  }

  preUpdate(time: number, delta: number){
    super.preUpdate(time, delta);

    if(this.active){

      // Recycle: kill enemy if it moves below camera view //
      Helpers.kill(this);

      // Mode: Change mode on player distance //
      const inRange = Phaser.Math.Distance.Between(this.scene.player.x, this.scene.player.y, this.x, this.y) < this.modes.attack.radius;
      if(inRange && this.modes.current !== 'attack'){
        this.setMode('attack');
      }else if(!inRange && this.modes.current !== 'dormant'){
        this.setMode('dormant');
      }      
    }
  }
}