import Phaser from "phaser";

export default class Background extends Phaser.Scene {
  music!: Phaser.Sound.BaseSound;
  
  constructor() {
    super({
      key: "background"
    });
  }
  
  create() {
    const background = this.add.image(this.cameras.main.centerX ,this.cameras.main.centerY,'background').setOrigin(0.5, 0.5)
    background.setDisplaySize(this.cameras.main.width, this.cameras.main.height)
    
    
    // Notify that game has booted
    this.game.events.emit("booted");
  }
  
  setColor(color:  number) {
    this.cameras.main.setBackgroundColor(color ? color : 0x000000);
  }
  
  playMusic() {
    this.stopMusic();
    
    //Create music instance & play
    this.music = this.sound.add("background-music");
    this.music.play({loop: true, volume: 0.1});
  }
  
  stopMusic() {
    if(this.music) {
      this.music.destroy();
    }
  }
}