import Phaser from "phaser";
import Scaling from "../../configs/scaling";

export default class UIPowerups extends Phaser.GameObjects.Container {
  current: number;
  amount: number;
  rexShaker: any;

  constructor(scene: Phaser.Scene, x: number, y: number, amount: number) {
    super(scene, x, y);
    this.scene.add.existing(this);

    // Settings //
    this.current = amount; 
    this.amount = amount ? amount : 1;

    // Powerups //
    for(let index = 0; index < this.amount; index++){
      this.addPowerup(index);
    }
  }

  addPowerup(current: number){
    const powerup = this.scene.add.image(0, 0, 'powerup_ui_filled');
    powerup.setX(-((powerup.getBounds().width + Scaling.getPixelbyDPR(8)) * current));
    powerup.setData('id', current + 1);
    powerup.setOrigin(1, 0);

    this.rexShaker = this.scene.plugins.get('rexShakePosition') as any;
    const _powerup = powerup as any
    _powerup.shake = this.rexShaker.add(powerup, { 
      duration: 1000,
      magnitude: 5,
      mode: 'effect'
    });

    this.add(_powerup);
  }

  used(){
    this.current--;
    this.update();
  }

  recover(){
    this.current++;
    this.update();
  }

  update(){
    this.each((powerup: Phaser.GameObjects.Image) => {
      if(powerup.getData('id') > this.current){
        powerup.setTexture('powerup_ui_empty');
        const _powerup = powerup as any
        _powerup.shake.shake()
      }else {
        powerup.setTexture('powerup_ui_filled');
      }
    })
  }
}