import Constants from "./constants";
import ITrackingData from "../interfaces/ITrackingData";

const normalizeValue = function (value: number, min: number, max: number) {
    return (value - min) / (max - min);
};

const linearInterpolation = function (norm: number, min: number, max: number) {
    return (max - min) * norm + min;
};

const getPixelsFromScore = function(score: number, viewportHeight: number){
    return Math.floor((score * Constants.PIXELS_PER_METER) - viewportHeight + Constants.PLAYER_Y_OFFSET);
}

const getScoreFromPixels = function(pixels: number){
    return Math.floor(Math.abs(pixels + Constants.PLAYER_Y_OFFSET) / Constants.PIXELS_PER_METER);
}

const kill = function(gameObject: any){
    if(gameObject.y - (gameObject.height * gameObject.originY * gameObject.scale) > (gameObject.scene.cameras.main.scrollY + gameObject.scene.cameras.main.height)){
        gameObject.kill();
    }
}

const sendTracking = function(event: ITrackingData){
    const root = (window as any);
    root.dataLayer = root.dataLayer || [];
    root.dataLayer.push(event);  
}

export {
    normalizeValue,
    sendTracking,
    linearInterpolation,
    getPixelsFromScore,
    getScoreFromPixels,
    kill
}
