import Phaser from "phaser";
import * as Helpers from "../../../configs/helpers";
import EnemyBase from "./enemyBase";

export default class EnemyBox extends EnemyBase {
  types: { left: string; right: string; }[];


  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y, 'enemy_box_left');
    this.scene.add.existing(this);

    // Settings //
    this.types = [{
      left:'enemy_box_left',
      right:'enemy_box_right'
    },{
      left:'enemy_box_two_left',
      right:'enemy_box_two_right'
    }]    

    // Type //
    this.setName('enemyBox');

    // Styling //
    this.setScale(0.6);
    this.setActive(false).setVisible(false);

    // Physics //
    this.scene.physics.world.enable(this);    
  }
  
  kill(){
    this.setActive(false).setVisible(false);
  }

  revive(x: number, y: number){
    const type = Phaser.Math.RND.pick(this.types);
    const direction = x < this.scene.cameras.main.centerX ? 'left' : 'right';

    this.setTexture(direction === 'left' ? type['left'] : type['right']);
    this.setOrigin(direction === 'left' ? 0 : 1, 0.5);
    this.setPosition(direction === 'left' ? 0 : this.scene.cameras.main.width, y);

    this.setVisible(true).setActive(true);
  }  

  preUpdate(time: number, delta: number){
    super.preUpdate(time, delta);

    if(this.active){

      // Recycle: kill enemy if it moves below camera view //
      Helpers.kill(this);
    }
  }  
}