import Main from "@/game/climber/states/main";
import Phaser from "phaser";
import Scaling from "../../configs/scaling";

export default class Sky extends Phaser.GameObjects.Sprite {
  declare scene: Main;
  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y, "");

    this.scene.add.existing(this);

    // Texture: Generate or re-use sky texture //
    const texture = this.generateTexture();
    this.setTexture(texture);

    // Fixed to scene camera //
    this.setScrollFactor(0);

    // DayCycle: Add to cycle //
    this.scene.dayCycle.addSprite(this, {
      colors: {
        night:[0x6C40B7],
        day:[0xFF418C]
      },
      isFillTint:true
    })      
  }

  generateTexture(){
    if(!this.scene.textures.exists('sky')){
      const textureBackground = this.scene.make.graphics({x:0, y:0, add:false});
      textureBackground.fillStyle(0x6C40B7 , 1);
      textureBackground.fillRect(0, 0, this.scene.cameras.main.width, this.scene.cameras.main.height);
      textureBackground.generateTexture('sky', this.scene.cameras.main.width, this.scene.cameras.main.height);
    }

    return 'sky';
  }
}