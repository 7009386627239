import Scaling from "./scaling";

export default class Constants {

    // Fonts //
    static FONT_BOLD = "font_bold";
    static FONT_REGULAR = "font_regular";

    // General //
    static WALL_WIDTH = Scaling.getPixelbyDPR(40);
    static LEVEL = 1;

    // Player //
    static PLAYER_LIVES = 3;
    static PLAYER_DASH_AMOUNT = 3;
    static PLAYER_Y_OFFSET = Scaling.getPixelbyDPR(80);

    // DayCycle //
    static DAYCYCLE_PROGRESS_PER_METER = 5;
    static DAYCYCLE_CELESTIAL_TWEEN_SPEED = 1000;

    // Meters //
    static PIXELS_PER_METER = 200;
}