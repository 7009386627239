import IinputManager from "../../interfaces/IinputManager";

export enum SwipeDirection {
	NORTH,
	NORTHEAST,
	EAST,
	SOUTHEAST,
	SOUTH,
	SOUTHWEST,
	WEST,
	NORTHWEST
}

export default class InputManager {
	scene: Phaser.Scene;
	swipeCallback: (pointer: Phaser.Input.Pointer, direction: SwipeDirection, result: Phaser.Math.Vector2) => void;
	clickdownCallback: (pointer: Phaser.Input.Pointer) => void
	clickupCallback: (pointer: Phaser.Input.Pointer) => void

	x = 0;
	y = 0;
	usedSwipe = false;
	firstClick = false;
	minSwipeDistance = 100;

	constructor(scene: Phaser.Scene, configs: IinputManager) {
		this.scene = scene
		this.swipeCallback = configs.callbackSwipe;
		this.clickdownCallback = configs.callbackDown;
		this.clickupCallback = configs.callbackUp;
	}	

	update() {
		const pointer = this.scene.input.activePointer

		if (pointer.isDown && !this.usedSwipe) {

			// set initial click value
			if (!this.firstClick) {
				this.x = pointer.x
				this.y = pointer.y
				this.firstClick = true

				this.clickdownCallback(pointer);

			} else {
				// wait for hit swipe distance
				const currentPoint = new Phaser.Math.Vector2(this.x, this.y)
				const distance = Phaser.Math.Distance.BetweenPoints(currentPoint, pointer.position)
				
				if (distance > this.minSwipeDistance) {
					const endPoint = new Phaser.Math.Vector2(pointer.x, pointer.y)
					const dirNormalized = new Phaser.Math.Vector2(endPoint.x - currentPoint.x, endPoint.y - currentPoint.y).normalize()
					const rounded = new Phaser.Math.Vector2(Math.round(dirNormalized.x), Math.round(dirNormalized.y))
					this.usedSwipe = true

					// return normalized vector
					let direction;
					if (rounded.x === 0) {
						if (rounded.y === 1) direction = SwipeDirection.SOUTH;
						if (rounded.y === -1) direction = SwipeDirection.NORTH;
					}

					if (rounded.x === -1) {
						if (rounded.y === 1) direction = SwipeDirection.SOUTHWEST;
						if (rounded.y === 0) direction = SwipeDirection.WEST;
						if (rounded.y === -1) direction = SwipeDirection.NORTHWEST;
					}

					if (rounded.x === 1) {
						if (rounded.y === 1) direction = SwipeDirection.SOUTHEAST;
						if (rounded.y === 0) direction = SwipeDirection.EAST;
						if (rounded.y === -1) direction = SwipeDirection.NORTHEAST;
					}

					this.swipeCallback(pointer, direction || SwipeDirection.NORTH, rounded)
				}
			}
		}

		// reset
		if (!pointer.isDown) {
			// call back exist && has clicked one && did not use a swipe
			if(this.clickupCallback && this.firstClick && !this.usedSwipe) {			

				this.clickupCallback(pointer);
			}

			this.x = 0
			this.y = 0
			this.firstClick = false
			this.usedSwipe = false
		}
	}

}
