import Phaser from "phaser";
import Scaling from "../../configs/scaling";
import * as Helpers from "../../configs/helpers";

export default class Sign extends Phaser.GameObjects.Sprite {
  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y, '');
    this.scene.add.existing(this);

    // Styling //
    this.setOrigin(0.5, 1);
    this.setActive(false).setVisible(false);
  }

  kill(){
    this.setOrigin(0.5, 1);
    this.setFlipX(false);
    
    this.setActive(false).setVisible(false);
  }

  revive(x: number, y: number, type: string){
    switch(type){
      case 'small':
        const direction = x < this.scene.cameras.main.centerX ? 'left' : 'right';
        this.setOrigin(direction === 'left' ? 0 : 1, 0.5);
        this.setFlipX(direction === 'left' ? false : true);
        this.setPosition(direction === 'left' ? 0 : this.scene.cameras.main.width, y);
        break;

      case 'sign_big':
        this.setPosition(x, y);
        break;
    }

    this.setVisible(true).setActive(true);
  }  

  preUpdate(time: number, delta: number){
    super.preUpdate(time, delta);

    if(this.active){
      // Recycle: kill enemy if it moves below camera view //
      Helpers.kill(this);
    }
  } 
}