import Main from "@/game/climber/states/main";
import Phaser from "phaser";

export default class ElectricFence extends Phaser.GameObjects.TileSprite {
  declare body: Phaser.Physics.Arcade.Body;
  declare scene: Main;
  speed: number;
  offset: any;

  constructor(scene: Phaser.Scene, x: number, y: any, width: number, height: number, offset: any) {
    super(scene, x, y + offset, width, height, 'object_electric_fence');
    this.scene.add.existing(this);

    // Settings //
    this.speed = 3;
    this.offset = offset;

    // Physics //
    this.scene.physics.world.enable(this);    
  }

  hit(){
    this.scene.audio_player_hit.play();
    this.body.setEnable(false);
  }

  update(){
    this.tilePositionX += 1;

    // Movement: when play is playing, move the fence //
    const positionY = this.scene.player.y + this.offset;
    if(positionY < this.y){
      this.y = positionY;
    }
  }
}