import Main from "@/game/climber/states/main";
import Phaser from "phaser";
import IShadingSettings from "../../interfaces/DayCycle/IShadingSettings";
import Arch from "../sprites/arch";

export default class Archs extends Phaser.GameObjects.Group {
  declare scene: Main;
  constructor(scene: Phaser.Scene, amount: number) {
    super(scene);

    for(let index = 0; index < amount; index++){
      const arch = new Arch(this.scene, 0, 0);
      this.add(arch);
    }

    // DayCycle: Add to cycle //
    this.getChildren().forEach(arch => {
      this.scene.dayCycle.addSprite(arch, {
        colors: {
          night:[0x46287B],
          day:[0xed2172]
        }
      })     
    })    
  }

  spawn(y: number){
    const arch = this.getFirstDead();
    
    // Tint: Set current DayCycle tint //
    const shadingObject = this.scene.dayCycle.shading.find(object => object.shadingId === arch.shadingId) as IShadingSettings;
    this.scene.dayCycle.updateTint(shadingObject, this.scene.dayCycle.cycle.start, this.scene.dayCycle.cycle.currentStep);

    // arch: spawn //
    arch ? arch.revive(y) : null;
  }
}