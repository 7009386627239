import Phaser from "phaser";
import Cloud from "../sprites/cloud";

export default class Clouds extends Phaser.GameObjects.Group {
  types: string[];
  
  constructor(scene: Phaser.Scene, amount: number) {
    super(scene);

    this.types = ['cloud_extrasmall','cloud_small','cloud_medium'];

    for(let index = 0; index < amount; index++){
      const cloud = new Cloud(this.scene, 0, 0);
      this.add(cloud);
    }
  }

  spawn(x: number, y: number){
    const cloud = this.getFirstDead();

    // Texture //
    cloud.setTexture(Phaser.Math.RND.pick(this.types));
    
    // cloud: spawn //
    cloud ? cloud.revive(x, y) : null;
  }
}