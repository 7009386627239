import Main from "@/game/climber/states/main";
import Scaling from "../../configs/scaling";
import ILevelLayout, { IbackgroundItem as IBackgroundItem, IBackgroundLayout} from "../../interfaces/LevelBuilder/ILevelLayout";
import ILevelSetting, {IPartition} from "../../interfaces/LevelBuilder/ILevelBuilderSetting"
import Archs from "../groups/archs";
import Clouds from "../groups/clouds";
import Enemies from "../groups/enemies";
import Powerups from "../groups/powerups";
import Signs from "../groups/signs";
import Skylines from "../groups/skylines";
import Ground from "../sprites/ground";
import IObstacleLayout, { IEnemyItem } from "../../interfaces/LevelBuilder/IEnemyLayout";

export default class LevelBuilder {
  scene: Main;
  layoutObstacles: IObstacleLayout;
  layoutBackground: ILevelLayout;

  enemies: Enemies;
  ground: Ground;
  signs: Signs;
  archs: Archs;
  clouds: Clouds;
  skylines: Skylines;
  powerups: Powerups;

  hasEnded: boolean;
  partition: IPartition
  
  debug!: boolean;
  debugBackgroundLayout!: number;
  debugObstaclesLayout!: number;

  constructor(scene: Main, settings: ILevelSetting, debug: boolean){
    this.scene = scene;

    // Data //
    this.layoutObstacles = this.scene.cache.json.get('json_layouts_obstacles');
    this.layoutBackground = this.scene.cache.json.get('json_layouts_background');

    // Settings //
    this.enemies = settings.enemies;
    this.ground = settings.ground;
    this.signs = settings.signs;
    this.archs = settings.archs;
    this.clouds = settings.clouds;
    this.skylines = settings.skylines;
    this.powerups = settings.powerups;
    this.hasEnded = false;

    // Partitions //
    this.partition = {
      current:-1,
      prerendered:3,
      height: this.scene.cameras.main.height,
      width: this.scene.cameras.main.width
    };

    // Debug //
    if(debug){
      this.debug = true;
      this.debugBackgroundLayout = 8;
      this.debugObstaclesLayout = 0;
      this.partition.prerendered = 2;
    }

    // Start //
    this.createStartingPoint();
  }

  getPositionX(x: number){
    const minX = this.scene.worldBoundLeft;
    const maxX = this.scene.worldBoundRight;    
    return (minX + ((maxX - minX) / 100) * x);
  }

  getPositionY(y: number){
    const bottomY = -(this.partition.height * this.partition.current);
    return bottomY - ((this.partition.height / 100) * y);
  }

  createStartingPoint(){
    if(this.debug){return false;}

    // Skylines //
    // false
    this.skylines.spawn(this.ground.getBounds().top, false);
    this.skylines.spawn(this.ground.getBounds().top, true);

    // Clouds //
    this.clouds.spawn(0, this.scene.cameras.main.centerY - Scaling.getPixelbyDPR(265));
    this.clouds.spawn(this.scene.cameras.main.width, this.scene.cameras.main.centerY - Scaling.getPixelbyDPR(100));
    this.clouds.spawn(0, this.scene.cameras.main.centerY + Scaling.getPixelbyDPR(75));

    // Signs //
    this.signs.spawn(this.scene.cameras.main.centerX, this.ground.getBounds().top, 'sign_big');
    this.signs.spawn(0, this.scene.cameras.main.centerY - Scaling.getPixelbyDPR(120));

    // Enemies //
    this.enemies.spawn(this.scene.worldBoundRight - Scaling.getPixelbyDPR(80), Scaling.getPixelbyDPR(130), 'enemyMoving');  

    // Light: set one-off light sprite //
    this.scene.groundLight.setActive(true).setVisible(true);
  }

  createEnding(){
    this.hasEnded = true;

    // Arch //
    this.archs.spawn(this.getPositionY(50));

    // Signpost //
    this.scene.pole.revive(this.scene.cameras.main.centerX, this.getPositionY(50));

    // Clouds //
    this.clouds.spawn(0, this.scene.cameras.main.centerY - Scaling.getPixelbyDPR(265));
    this.clouds.spawn(this.scene.cameras.main.width, this.scene.cameras.main.centerY - Scaling.getPixelbyDPR(100));
    this.clouds.spawn(0, this.scene.cameras.main.centerY + Scaling.getPixelbyDPR(75));    
  }

  createPartition(current: number){
    if(current === -1 && !this.debug){return false;}

    // Background: prevent same twice //
    const backgroundPool = this.partition.backgroundId ? this.layoutBackground.layouts.filter((layout: IBackgroundLayout) => layout.id !== this.partition.backgroundId) : this.layoutBackground.layouts;

    // Background: pick layout //
    const backgroundPicked = !this.debug ? Phaser.Math.RND.pick(backgroundPool) : backgroundPool[this.debugBackgroundLayout];
    backgroundPicked.objects.forEach((object: IBackgroundItem) => this.createBackgroundOject(object));

    // Background: save picked ID //
    this.partition.backgroundId = !this.debug ? backgroundPicked.id : undefined;

    // Obstacles: prevent same twice  //
    const obstaclesPool = this.partition.obstaclesId ? this.layoutObstacles.layouts.filter((layout: { id: number; }) => layout.id !== this.partition.obstaclesId) : this.layoutObstacles.layouts;
    
    // Obstacles: pick layout //
    const obstaclesPicked = !this.debug ? Phaser.Math.RND.pick(obstaclesPool) : obstaclesPool[this.debugObstaclesLayout];

    obstaclesPicked.objects.forEach((obstacle: IEnemyItem) => this.createObstacle(obstacle));

    // Obstacles: save picked ID //
    this.partition.obstaclesId = !this.debug ? obstaclesPicked.id : undefined;

    // Debug: show picked objects //
    if(this.debug){
      console.log('[DEBUG] Background:',backgroundPicked);
      console.log('[DEBUG] Obstacles:', obstaclesPicked);
    }
  }

  createBackgroundOject(object: IBackgroundItem){
    const type = object.type;

    switch(type){
      case 'cloud':
        this.clouds.spawn(this.getPositionX(object.position.x), this.getPositionY(object.position.y));
      break;
      case 'arch':
        this.archs.spawn(this.getPositionY(object.position.y));
      break;   
      case 'skyline':
        this.skylines.spawn(this.getPositionY(object.position.y), false);
      break;   
      case 'sign':
        this.signs.spawn(this.getPositionX(object.position.x), this.getPositionY(object.position.y));
      break;                  
    }    
  }

  createObstacle(obstacle: IEnemyItem){
    const type = obstacle.type;

    switch(type){
      case 'enemy':
        const enemyType = obstacle.enemyType;
        this.enemies.spawn(this.getPositionX(obstacle.position.x), this.getPositionY(obstacle.position.y), enemyType);
      break;
      case 'powerup':
        const powerups = this.powerups.types.filter(item => item.isSpawnable === true);
        if(powerups.length){
          const powerupPicked = Phaser.Math.RND.pick(powerups);
          this.powerups.spawn(this.getPositionX(obstacle.position.x), this.getPositionY(obstacle.position.y), powerupPicked);
        }
      break;
    }
  }

  update(){
    if((this.scene.cameras.main.scrollY + this.partition.height) - (this.partition.height * (this.partition.prerendered - 1)) < -(this.partition.height * (this.partition.current))){
      if(this.scene.dayCycle.finished && !this.hasEnded){
        this.createEnding();
      }else {
        this.createPartition(this.partition.current);
      }
      this.partition.current++;
    }
  }  

}