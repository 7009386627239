import Phaser from "phaser";
import Scaling from "../../configs/scaling";

export default class Ground extends Phaser.GameObjects.TileSprite {
  declare body: Phaser.Physics.Arcade.Body;
  
  constructor(scene: Phaser.Scene, x: number, y: number, width: number, height: number) {
    super(scene, x, y, width, height, 'floor');
    this.scene.add.existing(this);

    // Physics //
    this.scene.physics.world.enable(this);    
    this.body.setImmovable(true);    
  }
}