import Phaser from "phaser";
import Constants from "../../configs/constants";
import Scaling from "../../configs/scaling";

export default class Scoreboard extends Phaser.GameObjects.Container {
  text: Phaser.GameObjects.Text;

  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y);
    this.scene.add.existing(this);

    // Background //
    const background = this.scene.add.graphics()
    background.fillRoundedRect(0, 0, Scaling.getPixelbyDPR(106), Scaling.getPixelbyDPR(44), Scaling.getPixelbyDPR(6))

    // Icon //
    const icon = this.scene.add.image(Scaling.getPixelbyDPR(2), Scaling.getPixelbyDPR(1.5), 'icon_coin').setOrigin(0,0)
    // note: multiply the width with the scale that has been set in userinterface
    const maskIcon = scene.make.graphics({}, false)
    maskIcon.fillRoundedRect(x + icon.x, y + icon.y, icon.width, icon.height, Scaling.getPixelbyDPR(6))
    icon.setMask(maskIcon.createGeometryMask())

    // Score //
    this.text = this.scene.add.text(icon.getBounds().right + Scaling.getPixelbyDPR(8), icon.getCenter().x, "0", {
      fontFamily: Constants.FONT_BOLD,
      fontSize: `${Scaling.getPixelbyDPR(24)}px`,
      align: "center",
      color: "#000000"
    }).setOrigin(0, 0.5).setName('scoreText');

    // Container: add childs //
    this.add([background, this.text, icon]);
  }

  update(score: number){
    this.text.setText(`${(score)}`);
  }
}