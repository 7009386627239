import Phaser from "phaser";
import Scaling from "../../configs/scaling";

export default class Lives extends Phaser.GameObjects.Container {
  current: number;
  amount: number;
  rexShaker: any;

  constructor(scene: Phaser.Scene, x: number, y: number, amount: number) {
    super(scene, x, y);
    this.scene.add.existing(this);

    const background = this.scene.add.graphics().setName("background")
    background.fillRoundedRect(-Scaling.getPixelbyDPR(86), -Scaling.getPixelbyDPR(20), Scaling.getPixelbyDPR(100), Scaling.getPixelbyDPR(40), 12)
    
    // Settings //
    this.rexShaker = this.scene.plugins.get('rexShakePosition') as any;
    this.current = amount; 
    this.amount = amount ? amount : 1;
    
    // Lives: add //
    for(let index = 0; index < this.amount; index++){
      this.addLife(index);
    }
    
    this.add(background)
    this.sendToBack(background)
  }

  addLife(current: number){
    const life = this.scene.add.image(0, 0, 'icon_life_full');
    life.setX(-((life.getBounds().width + Scaling.getPixelbyDPR(8)) * current));
    life.setData('id', current + 1);
    life.setOrigin(1, 0.5);

    const _life = life as any
    _life.shake = this.rexShaker.add(life, { 
      duration: 1000, 
      mode: 'effect'
    });

    this.add(_life);
  }

  lose(){
    this.current--;
    this.update();
  }

  loseAll() {
    this.current = 0;
    this.update();
  }

  recover(){
    this.current++;
    this.update();
  }

  update(){
    this.each((life: Phaser.GameObjects.Sprite) => {
      if(life.name === "background") {
        return
      }      

      if(life.getData('id') > this.current){
        life.setTexture('icon_life_empty');
        const lifeShake = life as any
        lifeShake.shake.shake()
      }else {
        life.setTexture('icon_life_full');
      }
    })
  }
}