import Main from "@/game/climber/states/main";
import scoreController from "@/utils/game/ScoreController";
import sessionSaveFile from "@/utils/game/SessionSaveFile";
import Phaser from "phaser";
import IPowerupType from "../../interfaces/IPowerupType";
import Powerup from "../sprites/powerup";

export default class Powerups extends Phaser.GameObjects.Group {
  declare scene: Main;
  types: IPowerupType[]
  constructor(scene: Phaser.Scene, amount: number) {
    super(scene);

    // Settings //
    this.types = [{
        type:'shield',
        frame:2,
        isSpawnable:true,
        cooldownInSeconds:60,
        onTriggerFunction:() => {
          this.scene.audio_powerup_default.play();
          this.scene.player.setShield(5000);
          sessionSaveFile.incrementValue('powerups', 1);
        }
      },{
        type:'health',
        frame:1,
        isSpawnable:true,
        cooldownInSeconds:30,
        onTriggerFunction:() => {
          this.scene.audio_powerup_heal.play();
          this.scene.player.hitpointsRecover();
          sessionSaveFile.incrementValue('powerups', 1);
        }
      },{
        type:'dash',
        frame:0,
        isSpawnable:true,
        cooldownInSeconds:20,
        onTriggerFunction:() => {
          this.scene.audio_powerup_default.play();
          this.scene.player.dashRecover();
          sessionSaveFile.incrementValue('powerups', 1);
        }
      }
    ];

    // Powerup: Add //
    for(let index = 0; index < amount; index++){
      const powerup = new Powerup(this.scene, 0, 0);
      this.add(powerup);
    }
  }

  setCooldownTimer(type: IPowerupType){
    type.isSpawnable = false;
    this.scene.time.delayedCall(type.cooldownInSeconds * 1000, () => {
      type.isSpawnable = true;
    })
  }

  spawn(x: number, y: number, type: IPowerupType){
    const powerup = this.getFirstDead() as Powerup;
    const powerupType = this.types.find(item => item.type === type.type) || this.types[0];
    
    // Cooldown: Disable spawning of type for period of time //
    this.setCooldownTimer(powerupType);

    // Type: setup the powerup with specific type //
    powerup.setType(powerupType);

    // Spawn //
    powerup ? powerup.revive(x, y) : null;
  }
}